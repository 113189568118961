import { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import {
  Typography, Box, Grid, IconButton,
} from '@mui/material';

import TrashIcon from 'public/svg/trash.svg';
import PlusIcon from 'public/svg/black-icon.svg';
import Input from 'src/components/Input';
import Autocomplete from 'src/components/Autocomplete';
import Button from 'src/components/Button';

import { getNomenclatures } from 'src/lib/api/requests';
import { required } from 'src/lib/validation';
import { convertorUnit } from 'src/lib/helper';
import { parseNumber } from 'src/lib/form/parse';

const BidNomenclatures = ({
  fields,
}) => {
  const [nomenclatureUnit, setNomenclatureUnit] = useState([]);
  const handleAdd = () => {
    fields.push({});
  };

  const handleRemove = (index) => {
    fields.remove(index);
  };
  const valueUnit = fields.value?.map((item) => item.nomenclature?.unit);

  useEffect(() => {
    if (valueUnit) {
      setNomenclatureUnit(valueUnit);
    }
  }, [fields]);
  return (

    <Box sx={{
      padding: '16px 0px 16px 0px',
    }}
    >
      <Typography sx={{ fontWeight: 600 }}>
        Номенклатуры
      </Typography>
      <Box sx={{ marginBottom: '24px' }}>
        {fields?.map((name, idx) => (
          <Grid container xs={12} sm={12} md={12} spacing={2} key={name} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item xs={5.5} md={5.5} sm={5.5}>
              <Field
                name={`${name}.nomenclature`}
                label="Номенклатура *"
                component={Autocomplete}
                getRequest={getNomenclatures}
                getRequestParams={{ is_active: true }}
                validate={required}
                paperSX={{ width: '300px' }}
              />
            </Grid>
            <Grid item sm={5.5} xs={5.5} md={5.5}>
              <Field
                name={`${name}.amount`}
                label={`Количество(${convertorUnit(nomenclatureUnit[idx])}) *`}
                component={Input}
                variant="standard"
                parse={parseNumber}
                validate={required}
              />
            </Grid>

            {fields.length > 1 && (
              <Box sx={{ display: 'flex', alignItems: 'flex-end', maxHeight: '70px' }}>
                <IconButton sx={{ padding: '0px', height: '34px' }} onClick={() => handleRemove(idx)}>
                  <TrashIcon />
                </IconButton>
              </Box>
            )}
          </Grid>
        ))}
      </Box>
      <Button
        title="Добавить"
        onClick={handleAdd}
        startIcon={<PlusIcon />}
        sx={{
          backgroundColor: 'transparent',
          color: '#000000',
          border: '1px solid #E9E9E9',
        }}
      />
    </Box>
  );
};

export default BidNomenclatures;
