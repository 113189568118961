import { identity } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { Grid, Box } from '@mui/material';

import CloseIcon from 'public/svg/close.svg';
import WarningRed from 'public/svg/warning.svg';
import Autocomplete from 'src/components/Autocomplete';
import BidNomenclatures from 'src/components/Forms/BidNomenclatures';
import CustomButton from 'src/components/Button';
import RadioButtons from 'src/components/RadioButtons';
import Input from 'src/components/Input';
import CustomDatePicker from 'src/components/DatePicker';
import AdditionalInfo from 'src/components/Info';
import Status from 'src/components/Table/Status';
import { FormTitle, StyledPaper } from 'src/components/Layout';
import useModalWindowsState from 'src/hooks/useModalWindowsState';

import { actions } from 'src/store/bid/slice';
import { getContractors, getUsers } from 'src/lib/api/requests';
import { required } from 'src/lib/validation';
import { parseBidData } from 'src/lib/helper';
import { CREATE_BID_FORM_RADIO_BUTTONS, USER_ROLES } from 'src/constants';
import Minus from 'public/svg/minus.svg';

interface Shipment {
  nomenclature?: object,
  amount?: string,
}

interface CreateBidFormInitialValues {
  periodicity: string,
  shipments: Shipment[]
}

const defaultInitialValues: CreateBidFormInitialValues = {
  periodicity: 'fixed',
  shipments: [{}],
};

const CreateBidForm = ({ isMobile }) => {
  const dispatch = useDispatch();

  const { modalWindowsState, setModalWindowsState } = useModalWindowsState();

  const bid = useSelector((store: any) => store.bid.bid, shallowEqual);
  const isEdit = typeof modalWindowsState.isCreateOpen === 'number';
  const initialValues = isEdit
    ? { ...parseBidData(bid), periodicity: 'interval' }
    : defaultInitialValues;

  const createBid = (data) => dispatch(actions.createBidStart(data));
  const editBid = (values) => dispatch(actions.editBidStart(values));

  const handleClose = () => setModalWindowsState({ isCreateOpen: false });

  const onSubmit = (data, valid): void => {
    if (valid) {
      if (isEdit) {
        editBid(data);
        setModalWindowsState({ isDetailsOpen: bid.id, isCreateOpen: false });
      } else {
        createBid(data);
        handleClose();
      }
    }
  };
  const buttonNameEdit = isMobile ? 'Сохранить' : 'Сохранить изменения';
  return (
    <StyledPaper isMobile={isMobile} elevation={3}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: isMobile ? '16px 16px 16px 16px' : '22px 19px 22px 32px',
          borderBottom: '1px solid #E9E9E9',
        }}
      >
        {isEdit
          ? (
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <FormTitle>
                Редактирование заявки
              </FormTitle>
              <Status statusId={bid.status} choiceFieldType="Bid" />
            </Box>
          )
          : <FormTitle>Создание заявки</FormTitle>}
        <CloseIcon onClick={handleClose} />
      </Box>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({
          handleSubmit, values, form,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                padding: isMobile ? '16px 16px 16px 16px' : '24px 32px 40px 32px',
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12}>
                  <Field
                    name="seller"
                    component={Autocomplete}
                    messageTooltip={(
                      <AdditionalInfo
                        isMobile={isMobile}
                        light="light"
                        valuesInfo="Не созданы площадки либо юр.лица"
                        data={null}
                      />
                    )}
                    nonSelectiveHint
                    iconTooltip={<WarningRed />}
                    label="Поставщик *"
                    getRequest={getContractors}
                    getRequestParams={{ type__contains: 6, is_active: true }}
                    displayName="name"
                    validate={required}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Field
                    name="manager"
                    label="Менеджер сделки *"
                    component={Autocomplete}
                    getRequestParams={{
                      role: USER_ROLES.manager,
                    }}
                    getRequest={getUsers}
                    displayName="full_name"
                    validate={required}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Field
                    name="periodicity"
                    render={({ input }) => (
                      <RadioButtons input={input} options={CREATE_BID_FORM_RADIO_BUTTONS} />
                    )}
                  />
                </Grid>
                {values.periodicity === 'fixed'
                  ? (
                    <Grid item xs={5.1} sm={5.1} md={5.1} sx={{ minWidth: '143px', paddingLeft: '6px' }}>
                      <Field
                        label="Дата забора сырья с *"
                        name="pickup_start_date"
                        component={CustomDatePicker}
                        validate={required}
                      />
                    </Grid>
                  ) : (
                    <Grid spacing={2} sx={{ display: 'flex', marginLeft: '2px', justifyContent: 'space-between' }}>
                      <Grid item xs={5.1} sm={5.1} md={5.1} sx={{ minWidth: '143px', paddingLeft: '6px' }}>
                        <Field
                          name="pickup_start_date"
                          label="Дата забора сырья с *"
                          component={CustomDatePicker}
                          validate={required}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          paddingTop: '18px',
                        }}
                        >
                          <Minus />
                        </Box>
                      </Grid>
                      <Grid item xs={5.1} sm={5.1} md={5.1} sx={{ minWidth: '143px', paddingLeft: '6px' }}>
                        <Field
                          name="pickup_end_date"
                          label="Дата забора сырья по"
                          component={CustomDatePicker}
                        />
                      </Grid>
                    </Grid>
                  )}
                <Grid item xs={12} sm={12} md={12}>
                  <Field
                    name="pickup_time"
                    label="Комментарий по времени забора"
                    component={Input}
                    variant="standard"
                    parse={identity}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Field
                    name="comment"
                    component={Input}
                    variant="standard"
                    label="Комментарий по заявке"
                    parse={identity}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FieldArray
                    name="shipments"
                    component={BidNomenclatures}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid #E9E9E9',
                padding: isMobile ? '16px' : '24px 33px',
              }}
            >
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
              >
                <CustomButton
                  title="Отменить"
                  onClick={() => {
                    form.reset();
                    handleClose();
                  }}
                  sx={{
                    background: '#ffffff',
                    color: '#000000',
                    border: '1px solid #E9E9E9',
                    marginRight: '16px',
                  }}
                />
                <CustomButton
                  type="submit"
                  title={isEdit ? buttonNameEdit : 'Создать заявку'}
                />
              </Box>
            </Box>
          </form>
        )}
      />
    </StyledPaper>
  );
};

export default CreateBidForm;
