import React from 'react';
import Popover from '@mui/material/Popover';
import { getNomenclaturesFromShipments } from 'src/lib/helper';
import { MinimizedNomenclatures, NomenclaturesPopover } from 'src/components/Layout';

const Nomenclatures = ({ shipments }) => {
  const nomenclatures = getNomenclaturesFromShipments(shipments);
  const nomenclaturesCount = nomenclatures.length;
  const isTooManyNomenclatures = nomenclatures.length >= 5;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div>
      {isTooManyNomenclatures
        ? (
          <div>
            <MinimizedNomenclatures
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              {nomenclaturesCount} позиций
            </MinimizedNomenclatures>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: 'none',
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <NomenclaturesPopover>
                {nomenclatures.map((nomenclature) => <div key={nomenclature}>{nomenclature}</div>)}
              </NomenclaturesPopover>
            </Popover>
          </div>
        )
        : (<div>{nomenclatures.join(', ')}</div>)}
    </div>
  );
};

export default Nomenclatures;
