import { useEffect, useState } from 'react';
import type { NextPage } from 'next';
import moment from 'moment';
import { Box } from '@mui/material';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import withAuth from 'src/hocs/withAuth';
import CustomToolbar from 'src/components/Toolbar';
import CreateBidForm from 'src/components/Forms/CreateBidForm';
import BidDetails from 'src/components/BidDetails';
import ModalWindow from 'src/components/Popup';
import CancelReasonForm from 'src/components/Forms/CancelReasonForm';
import BidFilter from 'src/components/Filters/BidFilter';
import PageContent from 'src/components/PageContent';
import Meta from 'src/components/Meta';

import useModalWindowsState from 'src/hooks/useModalWindowsState';
import { useChoiceFields } from 'src/contexts/choiceFields';
import useFilterOpen from 'src/hooks/useFilterOpen';

import { actions as entity } from 'src/store/entity/slice';
import { actions as bidActions } from 'src/store/bid/slice';

import { checkIsFilterValuesEmpty } from 'src/lib/helper';
import { bidCardKeys, bidsTableColumns } from 'src/constants/bidsConstants';
import {
  BID_STATUSES,
  ENTITY_TYPES,
  SHEET_LOADING_SIZE,
  USER_POSITIONS,
  USER_ROLES,
} from 'src/constants';

const entityType = ENTITY_TYPES.BID_ENTITY;

const Home: NextPage = ({ isMobile }: any) => {
  const dispatch = useDispatch();
  const { isFilterOpen } = useFilterOpen();
  const [isFilterEmpty, setIsFilterEmpty] = useState(true);
  const { modalWindowsState, setModalWindowsState } = useModalWindowsState();

  const { status } = useChoiceFields('Bid');
  const getList = (values) => dispatch(entity.getListStart({ entityType, values }));
  const editBid = (values) => dispatch(bidActions.editBidStart(values));

  const { bid, bidList, filterValues } = useSelector((store: any) => ({
    bid: store.bid.bid,
    bidList: store.entity[entityType].list,
    filterValues: store.entity[entityType].filterValues,
  }), shallowEqual);

  const remodelsIfMobile = (data) => (isMobile ? (
    data.map((item) => ({
      ...item,
      created: moment(item.created).format('DD.MM.YYYY'),
      manager: item.manager.full_name,
      shipments: item.shipments.map((shipment) => shipment.nomenclature.name).join(', '),
      seller: item.seller.name,
      pickup_start_date: moment(item.pickup_start_date).format('DD.MM.YYYY'),
      status: status && status[item.status],
    }))
  ) : data);

  useEffect(() => {
    getList({ page_size: SHEET_LOADING_SIZE });
  }, []);

  useEffect(() => {
    setIsFilterEmpty(checkIsFilterValuesEmpty(filterValues));
  }, [filterValues]);

  return (
    <>
      <Meta title="Заявки" />
      <ModalWindow
        isMobile={isMobile}
        translate={isMobile ? 'translate(-50%, -44%)' : 'translate(-50%, -50%)'}
        open={!!modalWindowsState.isCreateOpen}
        isOverflow
        handleClose={() => setModalWindowsState({ isCreateOpen: false })}
      >
        <CreateBidForm isMobile={isMobile} />
      </ModalWindow>
      <ModalWindow
        translate={isMobile ? 'translate(-50%, -45%)' : 'translate(-50%, -50%)'}
        isMobile={isMobile}
        open={!!modalWindowsState.isDetailsOpen}
        handleClose={() => setModalWindowsState({ isDetailsOpen: false })}
      >
        <Box sx={{
          overflow: 'auto',
          ...(isMobile && {
            width: '100%',
            height: '100%',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }),
        }}
        >
          <BidDetails isMobile={isMobile} />
          <ModalWindow
            isMobile={isMobile}
            open={modalWindowsState.isCancelReasonOpen}
          >
            <CancelReasonForm
              isMobile={isMobile}
              setModalWindowsState={setModalWindowsState}
              entity="Bid"
              title="Отменить заявку"
              onCancelClick={editBid}
              id={bid?.id}
              status={BID_STATUSES.canceled}
            />
          </ModalWindow>
        </Box>
      </ModalWindow>
      <CustomToolbar
        isMobile={isMobile}
        title="Заявки"
        createButtonTitle="Создать заявку"
        onCreateButtonClick={() => setModalWindowsState({
          ...modalWindowsState,
          isCreateOpen: true,
        })}
      />
      <BidFilter
        entityType={entityType}
        isMobile={isMobile}
        isFilterEmpty={isFilterEmpty}
        filterValues={filterValues}
        childrenComponent={(
          <PageContent
            isFilterOpen={isFilterOpen}
            data={remodelsIfMobile(bidList) || []}
            dataCard={{ dataKeys: bidCardKeys }}
            isFilterEmpty={isFilterEmpty}
            createButtonName="Создать заявку"
            title="Пока не создано ни одной заявки"
            isMobile={isMobile}
            entityType={entityType}
            columns={bidsTableColumns}
            onRowClick={(id) => setModalWindowsState({
              ...modalWindowsState,
              isDetailsOpen: id,
            })}
          />
          )}
      />
    </>
  );
};

export default withAuth(Home)([
  { role: USER_ROLES.manager, position: USER_POSITIONS.common },
  { role: USER_ROLES.manager, position: USER_POSITIONS.chief },
]);
