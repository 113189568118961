import { get, map } from 'lodash';
import React, { ChangeEvent } from 'react';
import { Field } from 'react-final-form';
import {
  Grid, FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';

import InputForm from 'src/components/Input';
import { ErrorMessage, Label } from 'src/components/Layout';
import { FinalFormInput, FinalFormMetaInterface, RadioButtonOptionInterface } from 'src/interfaces';
import { required } from 'src/lib/validation';

interface RadioButtonsInterface {
  input: FinalFormInput,
  meta: FinalFormMetaInterface,
  options: RadioButtonOptionInterface[],
  isHorizontal?: boolean,
  disabled?: boolean,
  label: string
  defaultValue?: string | number,
}

const RadioButtons: React.FC<any> = (props: RadioButtonsInterface) => {
  const {
    input: { value, onChange },
    meta,
    options,
    isHorizontal,
    disabled,
    label,
    defaultValue,
  } = props;
  return (
    <FormControl sx={{ width: '100%' }}>
      <Label>{label}</Label>
      <RadioGroup
        sx={{ display: 'flex', flexDirection: `${isHorizontal ? 'row' : 'column'}` }}
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      >
        {map(options, (option, optionKey) => {
          const optionValue = get(option, 'value', optionKey);
          const optionLabel = get(option, 'label', option);
          return (
            <FormControlLabel
              key={optionValue}
              value={optionValue}
              control={<Radio />}
              label={optionLabel}
              disabled={disabled}
              defaultValue={defaultValue}
            />
          );
        })}
      </RadioGroup>
      {value === 'Другое' && (
        <Grid item xs={12} sm={12} md={12} sx={{ pl: '30px' }}>
          <Field
            name="other"
            component={InputForm}
            label="Введите другую единицу измерения *"
            validate={required}
            variant="standard"
            disabled={disabled}
          />
        </Grid>
      )}
      {meta?.error && meta?.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
    </FormControl>
  );
};

export default RadioButtons;
