import { Typography, Box } from '@mui/material';
import { ReactElement } from 'react';

interface ChipLabelProps {
  label: string,
  data?: string | ReactElement,
  isLabel?:boolean
}

const ChipLabel = ({ label, data, isLabel = true }: ChipLabelProps) => (
  <Box sx={{
    display: 'flex',
    flexDirection: 'row',
  }}
  >
    <Typography sx={{
      whiteSpace: 'break-spaces',
      display: 'flex',
      alignItems: 'center',
      minHeight: '24px',
    }}
    >
      {isLabel && label}
      {data}
    </Typography>
  </Box>
);

export default ChipLabel;
