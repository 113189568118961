import {
  Box,
} from '@mui/material';
import { LightTooltip } from 'src/components/Layout';

const CustomToolTip = ({ message, icon }) => (
  <LightTooltip
    title={message}
    placement="top-start"
  >
    <Box>
      {icon}
    </Box>
  </LightTooltip>
);

export default CustomToolTip;
