import isEmpty from 'lodash/isEmpty';
import { useForm, useFormState } from 'react-final-form';
import { Box, Typography } from '@mui/material';

import { StyledChip } from 'src/components/Layout';
import ChipLabel from 'src/components/FilterChips/ChipLabel';
import XIcon from 'public/svg/x.svg';

interface PropsChips {
  getLabelNames?: (values) => any,
  filterInitialValues?: any,
  isMobile?: boolean,
  filterValues?: any
}

const Chips = ({
  getLabelNames, filterInitialValues, isMobile, filterValues,
}: PropsChips) => {
  const { values } = useFormState();
  const { change, reset, submit } = useForm();
  const labelNames = getLabelNames(values);
  const isFilterEmpty = isEmpty(values);

  return (
    <Box sx={{
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      padding: '0px 16px 0px 16px',
    }}
    >
      {!isMobile && (
      <Typography sx={{
        fontSize: '16px',
        fontWeight: 600,
        marginRight: '8px',
      }}
      >
        Фильтр:
      </Typography>
      )}
      <Box sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        overflow: 'auto',
        flexWrap: 'wrap',
        ...(isMobile && {
          flexWrap: 'nowrap',
        }),
      }}
      >
        <StyledChip
          isMobile={isMobile}
          label={(<ChipLabel label="Сбросить все" data={<XIcon />} isLabel={!isMobile} />)}
          onClick={() => {
            reset(filterInitialValues);
            submit();
          }}
        />
        {!isFilterEmpty && Object.keys(filterValues).map((formKey: string) => {
          if (!isEmpty(filterValues[formKey])) {
            return (
              <StyledChip
                key={formKey}
                sx={{ whiteSpace: 'nowrap' }}
                isMobile={isMobile}
                label={labelNames[formKey]}
                onDelete={() => {
                  change(formKey, filterInitialValues[formKey]);
                  submit();
                }}
                deleteIcon={<XIcon />}
              />
            );
          }
          return null;
        })}
      </Box>
    </Box>
  );
};

export default Chips;
