import { useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Collapse, Grid } from '@mui/material';
import CustomDatePicker from 'src/components/DatePicker';
import CustomButton from 'src/components/Button';
import { BID_FILTER_INITIAL_VALUES, CONTRACTOR_TYPES, USER_ROLES } from 'src/constants';
import {
  FilterHeader,
  FilterFormContainer,
  TableChipsContainer, Wrapper, FilterContainer,
} from 'src/components/Layout';
import ModalWindow from 'src/components/Popup';
import MultipleAutocompleteComponent from 'src/components/MultipleAutocomplete';
import { useChoiceFields } from 'src/contexts/choiceFields';
import useFilterOpen from 'src/hooks/useFilterOpen';

import { getContractors, getNomenclatures, getUsers } from 'src/lib/api/requests';
import { actions as entity } from 'src/store/entity/slice';

import CheckIcon from 'public/svg/check.svg';
import CrossIcon from 'public/svg/cross.svg';
import CloseIcon from 'public/svg/close.svg';
import { FilterProps } from 'src/interfaces';
import Chips from '../FilterChips';
import { getBidsChipsLabelNames } from '../../constants/bidsConstants';

const BidFilterForm = ({
  handleSubmit,
  form,
  isMobile,
  toggleFilterOpen,
  getStatusOptions,
  getList,
}) => (
  <FilterFormContainer isMobile={isMobile}>
    <FilterHeader>
      <p>Фильтр</p>
      <CloseIcon onClick={toggleFilterOpen} />
    </FilterHeader>
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1} sx={!isMobile && { maxWidth: 360 }}>
        <Grid item xs={6} sm={6} md={6}>
          <Field
            name="created__gte"
            label="Дата создания"
            component={CustomDatePicker}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Field
            name="created__lte"
            label={' '}
            component={CustomDatePicker}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Field
            name="pickup_start_date__gte"
            label="Дата забора"
            component={CustomDatePicker}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Field
            name="pickup_start_date__lte"
            label={' '}
            component={CustomDatePicker}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Field
            name="seller_id__in"
            component={MultipleAutocompleteComponent}
            label="Поставщик"
            getRequest={getContractors}
            getRequestParams={{ type__contains: CONTRACTOR_TYPES.seller }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Field
            name="manager_id__in"
            label="Менеджер трейдинга"
            component={MultipleAutocompleteComponent}
            getRequest={getUsers}
            displayName="full_name"
            getRequestParams={{ role: USER_ROLES.manager }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Field
            name="shipments__nomenclature_id__in"
            label="Номенклатура"
            component={MultipleAutocompleteComponent}
            getRequest={getNomenclatures}
            getRequestParams={{ is_active: true }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Field
            name="status__in"
            label="Статус"
            component={MultipleAutocompleteComponent}
            getRequest={getStatusOptions}
            multiple
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CustomButton
            type="submit"
            title="Применить"
            startIcon={<CheckIcon />}
            sx={{ mr: '10px' }}
          />
          <CustomButton
            title="Сбросить"
            onClick={() => {
              form.reset();
              getList({});
            }}
            startIcon={<CrossIcon />}
            light
          />
        </Grid>
      </Grid>
    </form>
  </FilterFormContainer>
);

const BidFilter = ({
  entityType,
  isMobile = false,
  translateModalWindow,
  childrenComponent,
  isFilterEmpty,
  filterValues,
}: FilterProps) => {
  const dispatch = useDispatch();

  const { status } = useChoiceFields('Bid');
  const { isFilterOpen, toggleFilterOpen } = useFilterOpen();

  const getList = (values) => dispatch(entity.getListStart({ entityType, values }));

  const onSubmit = (values) => {
    getList(values);
    if (isMobile) {
      toggleFilterOpen();
    }
  };

  const getStatusOptions = () => {
    const options = [];
    if (status) {
      Object.keys(status).map((key) => options.push({ name: status[key], id: key }));
    }
    return { results: options };
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={BID_FILTER_INITIAL_VALUES}
      render={({ handleSubmit, form }) => (
        <Wrapper orientation="vertical" isMobile={isMobile}>
          <TableChipsContainer isFilterEmpty={!isFilterEmpty} isMobile={isMobile}>
            {!isFilterEmpty && (
              <Chips
                isMobile={isMobile}
                getLabelNames={getBidsChipsLabelNames}
                filterInitialValues={BID_FILTER_INITIAL_VALUES}
                filterValues={filterValues}
              />
            )}
          </TableChipsContainer>
          {isMobile ? (
            <FilterContainer>
              <ModalWindow
                isMobile={isMobile}
                translate={translateModalWindow}
                open={isFilterOpen}
                handleClose={toggleFilterOpen}
              >
                <BidFilterForm
                  isMobile={isMobile}
                  handleSubmit={handleSubmit}
                  form={form}
                  toggleFilterOpen={toggleFilterOpen}
                  getStatusOptions={getStatusOptions}
                  getList={getList}
                />
              </ModalWindow>
              {childrenComponent}
            </FilterContainer>
          )
            : (
              <FilterContainer>
                {childrenComponent}
                {isFilterOpen && (
                  <Collapse in={isFilterOpen} orientation="horizontal">
                    <BidFilterForm
                      isMobile={isMobile}
                      handleSubmit={handleSubmit}
                      form={form}
                      toggleFilterOpen={toggleFilterOpen}
                      getStatusOptions={getStatusOptions}
                      getList={getList}
                    />
                  </Collapse>
                )}
              </FilterContainer>
            )}
        </Wrapper>
      )}

    />
  );
};

export default BidFilter;
