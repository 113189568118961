import {
  BaseSyntheticEvent, useState, useEffect,
} from 'react';
import { LinearProgress, TextField, Autocomplete } from '@mui/material';

import { OptionInterface } from 'src/interfaces';
import useDebounce from 'src/hooks/useDebounce';
import { getAutocompleteList } from 'src/lib/helper';
import ChevronDown from 'public/svg/chevron-down.svg';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const MultipleAutocompleteComponent = (props) => {
  const {
    getRequest, label, input, displayName = 'name', getRequestParams = {},
  } = props;

  const debouncedValue = useDebounce(input.value, 200);

  const [dataList, setDataList] = useState([]);
  const loading = !dataList;

  const onItemClick = (event: BaseSyntheticEvent, newValue: any) => {
    input.onChange(newValue);
  };

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3);

      if (active && dataList) {
        setDataList([...dataList]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    const params = { ...getRequestParams };
    if (!loading) {
      getAutocompleteList(params, getRequest, setDataList, displayName);
    }
  }, [debouncedValue]);

  const defaultProps = {
    options: dataList || [],
    getOptionLabel: (option: OptionInterface | undefined) => option?.name || '',
  };
  return (
    <Autocomplete
      {...defaultProps}
      id={input.name}
      value={input.value}
      multiple
      disableCloseOnSelect
      onOpen={() => getAutocompleteList(getRequestParams, getRequest, setDataList, displayName)}
      loading
      loadingText={loading ? <LinearProgress /> : 'Нет данных'}
      popupIcon={<ChevronDown />}
      onChange={onItemClick}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="standard"
        />
      )}
    />
  );
};

export default MultipleAutocompleteComponent;
