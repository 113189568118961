import { Grid, CircularProgress } from '@mui/material';
import {
  Data, InfoBox, Title, Value,
} from 'src/components/Layout';

const Info = ({
  data, valuesInfo, light = '', isLoading = false, isMobile,
}) => (
  <Grid item xs={12} sm={12} md={12}>
    {data ? (
      <InfoBox light={light}>
        {isLoading ? <Grid sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></Grid>
          : (
            data.map(({ title, value }) => (
              <Data isMobile={isMobile} key={title}>
                <Title>
                  {title}
                </Title>
                <Value isMobile={isMobile}>
                  {value(valuesInfo)}
                </Value>
              </Data>
            ))
          )}
      </InfoBox>
    ) : (
      <InfoBox light={light}>
        <Data>
          <Value>{valuesInfo}</Value>
        </Data>
      </InfoBox>
    )}
  </Grid>
);
export default Info;
