import {
  BaseSyntheticEvent, useState, useEffect,
} from 'react';
import { Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import LinearProgress from '@mui/material/LinearProgress';

import Input from 'src/components/Input';
import ToolTipAutocomplete from 'src/components/ToolTipAutocomplete';
import ChevronDown from 'public/svg/chevron-down.svg';
import useDebounce from 'src/hooks/useDebounce';
import { getAutocompleteList } from 'src/lib/helper';
import { AutocompleteComponentProps, OptionInterface } from 'src/interfaces';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const AutocompleteComponent = ({
  getRequest,
  label,
  meta,
  input,
  required,
  displayName = 'name',
  getRequestParams,
  onChange,
  messageTooltip,
  disabled = false,
  iconTooltip,
  onMouseEnter,
  nonSelectiveHint,
  displayNameAdditionalInfo,
  paperSX,
}: AutocompleteComponentProps) => {
  const [dataList, setDataList] = useState(null);
  const loading = !dataList;
  const debouncedValue = useDebounce(input.value, 200);

  const onAutocompleteChange = (event: BaseSyntheticEvent, newValue: OptionInterface) => {
    if (onChange) {
      onChange(newValue);
    }
    input.onChange(newValue);
  };

  const onAutocompleteMouseEnter = (event: BaseSyntheticEvent, newValue: OptionInterface) => {
    if (onMouseEnter) {
      onMouseEnter(newValue);
    }
  };

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3);

      if (active && dataList) {
        setDataList(dataList);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    const searchKey = `${displayName}__icontains`;
    const params = { ...getRequestParams, [searchKey]: debouncedValue };
    if (!loading) {
      getAutocompleteList(params, getRequest, setDataList, displayName, displayNameAdditionalInfo);
    }
  }, [debouncedValue]);

  return (
    <Autocomplete
      value={input.value}
      options={dataList || []}
      getOptionLabel={(option: OptionInterface) => option?.name || ''}
      loadingText={loading ? <LinearProgress /> : 'Нет данных'}
      inputValue={input.value?.name || input.value}
      onOpen={
      () => getAutocompleteList(
        getRequestParams,
        getRequest,
        setDataList,
        displayName,
        displayNameAdditionalInfo,
      )
    }
      onChange={onAutocompleteChange}
      disabled={disabled}
      popupIcon={<ChevronDown />}
      sx={{ width: '100%' }}
      componentsProps={{
        paper: { sx: { ...paperSX } },
      }}
      renderOption={(props: any, option) => (
        <Grid
          {...props}
          xs={12}
          sx={{ display: 'flex', justifyContent: 'space-between', minWidth: '200px' }}
        >
          <Grid
            xs={12}
            sx={{ width: '300px' }}
          >{option.name}
          </Grid>
          <ToolTipAutocomplete
            nonSelectiveHint={nonSelectiveHint}
            messageTooltip={messageTooltip}
            iconTooltip={iconTooltip}
            havePlatform={option.have_platform}
            haveEntity={option.have_entity}
            onAutocompleteMouseEnter={onAutocompleteMouseEnter}
            option={option}
          />
        </Grid>
      )}
      renderInput={(params) => (
        <Input
          label={label}
          variant="standard"
          meta={meta}
          input={input}
          required={required}
          {...params}
        />
      )}
    />
  );
};
export default AutocompleteComponent;
