import Box from '@mui/material/Box';
import { useChoiceFields } from 'src/contexts/choiceFields';
import { BID_STATUSES } from 'src/constants';
import { Circle, NewStatusContainer } from 'src/components/Layout';
import { StatusProps } from 'src/interfaces/layoutInterfaces';

const Status = ({ statusId, choiceFieldType, isMobile }: StatusProps) => {
  const { status } = useChoiceFields(choiceFieldType);

  const isStatusNew = choiceFieldType === 'Bid' && statusId === BID_STATUSES.new;

  return (
    <Box sx={{ whiteSpace: 'nowrap' }}>
      {isStatusNew
        ? (
          <NewStatusContainer isMobile={isMobile}>
            {!isMobile && <Circle />} {status && status[statusId]}
          </NewStatusContainer>
        )
        : status && status[statusId]}
    </Box>
  );
};

export default Status;
