import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  Paper, Box, Typography, CircularProgress, Tooltip,
} from '@mui/material';

import Status from 'src/components/Table/Status';
import useModalWindowsState from 'src/hooks/useModalWindowsState';
import CloseIcon from 'public/svg/close.svg';
import Button from 'src/components/Button';
import { actions as bidActions } from 'src/store/bid/slice';
import { actions as entityActions } from 'src/store/entity/slice';
import { convertorUnit } from 'src/lib/helper';
import { BID_STATUSES, SIMPLE_DATE_FORMAT } from 'src/constants';
import {
  Block, FormFooter, FormHeader, Label, Value,
} from 'src/components/Layout';

const BidDetails = ({ isMobile }) => {
  const dispatch = useDispatch();

  const { push } = useRouter();
  const { modalWindowsState, setModalWindowsState } = useModalWindowsState();

  const { bid, loading, bidDealsList } = useSelector((store: any) => ({
    bid: store.bid.bid,
    loading: store.bid.loading,
    bidDealsList: store.entity.DealEntity.list,
  }), shallowEqual);

  const isNewBid = bid && bid.status === BID_STATUSES.new;
  const isDealOpen = bid && (
    bid.status === BID_STATUSES.new || bid.status === BID_STATUSES.inWork);
  const isCreateDealButtonDisabled = bidDealsList && bidDealsList.length === 5;
  const isNoDealsInBid = bidDealsList && bidDealsList.length === 0;

  const getBid = () => dispatch(bidActions.getBidStart({ id: modalWindowsState.isDetailsOpen }));
  const getBidDeals = () => dispatch(entityActions.getListStart({ entityType: 'DealEntity', values: { bid_id: bid.id } }));
  const editBid = (values) => dispatch(bidActions.editBidStart(values));

  useEffect(() => {
    getBid();
  }, []);

  useEffect(() => {
    if (bid) {
      getBidDeals();
    }
  }, [bid]);

  if (bid && !loading) {
    return (
      <Paper sx={{
        width: '660px',
        overflow: 'auto',
        ...(isMobile && {
          width: '100%',
          height: '100%',
        }),

      }}
      >
        <Box sx={{
          width: '100%',
          height: '100%',
        }}
        >
          <FormHeader>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 600,
                }}
              >
                Заявка ID {bid.id}
              </Typography>
              <Typography sx={{
                fontSize: '12px',
                fontWeight: 600,
                color: '#676767',
                marginLeft: '8px',
                marginRight: '16px',
              }}
              >
                от {moment(bid.created).format('DD.MM.YYYY, HH:mm')}
              </Typography>
              {!isMobile && <Status statusId={bid.status} choiceFieldType="Bid" />}
            </Box>

            <CloseIcon onClick={() => setModalWindowsState({ isDetailsOpen: false })} />
          </FormHeader>
          <Box sx={{ padding: '16px 32px 40px 32px' }}>
            {isMobile
              && (
              <Block>
                <Label>
                  Статус
                </Label>
                <Value>
                  <Status statusId={bid.status} choiceFieldType="Bid" />
                </Value>
              </Block>
              )}
            <Block>
              <Label>
                Поставщик
              </Label>
              <Value>
                {bid.seller.name}
              </Value>
            </Block>
            <Block>
              <Label>
                Менеджер сделки
              </Label>
              <Value>
                {bid.manager.full_name}
              </Value>
            </Block>
            <Block>
              <Label>
                Дата забора вторсырья
              </Label>
              <Value>
                {
                  `${moment(bid.pickup_start_date).format(SIMPLE_DATE_FORMAT)} - 
                  ${moment(bid.pickup_start_date).format(SIMPLE_DATE_FORMAT)}`
                }
              </Value>
            </Block>
            <Block>
              <Label>
                Комментарий по времени забора
              </Label>
              <Value>
                {bid.pickup_time}
              </Value>
            </Block>
            <Block>
              <Label>
                Комментарий
              </Label>
              <Value>
                {bid.comment}
              </Value>
            </Block>
            <Block>
              <Label>
                Номенклатуры
              </Label>
              {bid.shipments.map((shipment, index) => {
                const { nomenclature: { name, unit }, amount } = shipment;
                return (
                  <Value key={shipment.id}>
                    {`${index + 1}. ${name} ${Number(amount)} ${convertorUnit(unit)}`}
                  </Value>
                );
              })}
            </Block>
            {!isEmpty(bidDealsList) && (
              <Block>
                <Label>
                  Созданные сделки
                </Label>
                {bidDealsList.map((deal) => (
                  <Link href={`/deals/${deal.id}`} key={deal.id}>
                    <Value key={deal.id} sx={{ color: '#0E79EC', cursor: 'pointer' }}>
                      {`Сделка ID ${deal.id}`}
                    </Value>
                  </Link>
                ))}
              </Block>
            )}

          </Box>
          {isDealOpen && (isNewBid ? (
            <FormFooter isMobile={isMobile}>
              <Button
                title="Отменить заявку"
                onClick={() => setModalWindowsState({ isCancelReasonOpen: true })}
                light
                sx={{
                  ...(isMobile && {
                    minWidth: '220px',
                    mb: '10px',
                  }),
                }}
              />
              <Button
                title="Редактировать заявку"
                onClick={() => setModalWindowsState({
                  isCreateOpen: bid.id,
                  isDetailsOpen: false,
                })}
                light
                sx={{
                  ...(isMobile && {
                    minWidth: '220px',
                    mb: '10px',
                  }),
                }}
              />
              <Button
                title="Взять в работу"
                onClick={() => editBid({
                  id: bid.id,
                  status: BID_STATUSES.inWork,
                })}
                sx={{
                  ...(isMobile && {
                    minWidth: '220px',
                    mb: '10px',
                  }),
                }}
              />
            </FormFooter>
          ) : (
            <FormFooter isMobile={isMobile}>
              <Button
                title="Редактировать заявку"
                onClick={() => setModalWindowsState({
                  isCreateOpen: bid.id,
                  isDetailsOpen: false,
                })}
                light
                sx={{
                  ...(isMobile && {
                    minWidth: '220px',
                    mb: '10px',
                  }),
                }}
              />
              {isNoDealsInBid ? (
                <Button
                  title="Отменить заявку"
                  onClick={() => setModalWindowsState({ isCancelReasonOpen: true })}
                  light
                  sx={{
                    ...(isMobile && {
                      minWidth: '220px',
                      mb: '10px',
                    }),
                  }}
                />
              ) : (
                <Button
                  title="Завершить заявку"
                  onClick={() => editBid({
                    id: bid.id,
                    status: BID_STATUSES.dealCreated,
                  })}
                  light
                  sx={{
                    ...(isMobile && {
                      minWidth: '220px',
                      mb: '10px',
                    }),
                  }}
                />
              )}
              <Tooltip
                title={isCreateDealButtonDisabled
                  ? 'вы не можете создать больше 5 сделок для одной заявки'
                  : ''}
                placement="top"
              >
                <span>
                  <Button
                    title="Создать сделку"
                    onClick={() => {
                      setModalWindowsState({ isDetailsOpen: false });
                      push({ pathname: '/deals/create/', query: { bid: bid.id } });
                    }}
                    disabled={isCreateDealButtonDisabled}
                    sx={{
                      ...(isMobile && {
                        minWidth: '220px',
                      }),
                    }}
                  />
                </span>
              </Tooltip>
            </FormFooter>
          ))}
        </Box>
      </Paper>
    );
  }
  return <CircularProgress />;
};

export default BidDetails;
