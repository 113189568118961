import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Input from 'src/components/Input';

const CustomDatePicker = (props) => {
  const {
    meta, label, input, disabled = false,
  } = props;

  return (
    <DesktopDatePicker
      value={input.value || null}
      disabled={disabled}
      onChange={(newValue) => {
        input.onChange(newValue);
      }}
      label={label}
      renderInput={(params) => (
        <Input
          {...params}
          error={meta.touched && meta.error}
          meta={meta}
          variant="standard"
          input={input}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            ...params.inputProps,
            placeholder: 'ДД.ММ.ГГГГ',
          }}
        />
      )}
    />
  );
};

export default CustomDatePicker;
