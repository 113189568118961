import { Box, Paper, Typography } from '@mui/material';
import { Field, Form } from 'react-final-form';

import CloseIcon from 'public/svg/close.svg';
import AutocompleteComponent from 'src/components/Autocomplete';
import Button from 'src/components/Button';
import Input from 'src/components/Input';
import { useChoiceFields } from 'src/contexts/choiceFields';
import { required } from 'src/lib/validation';
import { FormFooter, FormHeader } from 'src/components/Layout';

const CancelReasonForm = ({
  setModalWindowsState, entity, title, onCancelClick, id, status, isMobile = false,
}) => {
  const { cancel_reason: cancelReason } = useChoiceFields(entity);

  const getCancelReasonOptions = () => {
    const options = [];
    Object.keys(cancelReason).map((key) => options.push({ name: cancelReason[key], id: key }));
    return { results: options };
  };
  const cancelReasonOther = '1';

  const onSubmit = (values) => {
    const requestData = { ...values, id, status };
    onCancelClick(requestData);
    setModalWindowsState({
      isCancelReasonOpen: false,
      isDetailsOpen: false,
    });
  };

  return (
    <Paper>
      <FormHeader>
        <Typography>
          {title}
        </Typography>
        <CloseIcon
          onClick={() => setModalWindowsState({ isCancelReasonOpen: false })}
        />
      </FormHeader>
      <Form
        onSubmit={onSubmit}
        initialValues={{ cancel_reason: undefined, cancel_reason_comment: undefined }}
        validate={(values) => {
          const errors: Record<string, string | undefined> = {};
          const cancelReasonComment = values.cancel_reason?.id === cancelReasonOther
            && required(values.cancel_reason_comment);
          if (cancelReasonComment) {
            errors.cancel_reason_comment = cancelReasonComment;
          }
          return errors;
        }}
        render={({ handleSubmit, values }) => {
          const isReasonOther = values.cancel_reason?.id === cancelReasonOther;
          return (
            <form onSubmit={handleSubmit}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: isMobile ? '100%' : '508px',
                padding: '24px 32px 40px 32px',
              }}
              >
                <Field
                  name="cancel_reason"
                  component={AutocompleteComponent}
                  variant="standard"
                  label="Причина отмены *"
                  getRequest={getCancelReasonOptions}
                  validate={required}
                />
                <Field
                  name="cancel_reason_comment"
                  component={Input}
                  variant="standard"
                  label={`Комментарий ${isReasonOther ? '*' : ''}`}
                  sx={{
                    marginTop: '16px',
                  }}
                />
              </Box>
              <FormFooter
                isMobile={isMobile}
              >
                <Button
                  title="Не отменять"
                  onClick={() => setModalWindowsState({ isCancelReasonOpen: false })}
                  sx={{
                    marginRight: isMobile ? '0px' : '16px',
                    minWidth: '220px',
                    marginBottom: '10px',
                  }}
                  light
                />
                <Button
                  title={title}
                  type="submit"
                  light
                  sx={{
                    minWidth: '220px',
                  }}
                />
              </FormFooter>
            </form>
          );
        }}
      />

    </Paper>
  );
};

export default CancelReasonForm;
