import moment from 'moment';
import get from 'lodash/get';

import Nomenclatures from 'src/components/Table/Nomenclatures';
import Status from 'src/components/Table/Status';
import CommentComponent from 'src/components/Comment';
import ChipLabel from 'src/components/FilterChips/ChipLabel';
import { TableColumn, Card } from 'src/interfaces';

export const bidsTableColumns: TableColumn[] = [
  {
    title: 'ID',
    name: 'id',
  },
  {
    title: 'Статус',
    name: 'status',
    render: ({ status }) => <Status statusId={status} choiceFieldType="Bid" />,
  },
  {
    title: 'Менеджер сделки',
    name: 'manager',
    render: ({ manager }) => get(manager, 'full_name'),
  },
  {
    title: 'Поставщик',
    name: 'seller',
    render: ({ seller }) => get(seller, 'name'),
  },
  {
    title: 'Номенклатуры',
    name: 'shipments',
    render: ({ shipments }) => shipments && <Nomenclatures shipments={shipments} />,
  },
  {
    title: 'Дата забора',
    name: 'pickup_start_date',
    render: ({ pickup_start_date: pickupStarDate }) => moment(pickupStarDate).format('DD.MM.YYYY'),
  },
  {
    title: '',
    name: 'comment',
    render: ({ comment }) => comment && <CommentComponent comment={comment} />,
  },
  {
    title: 'Дата создания',
    name: 'created',
    render: ({ created }) => moment(created).format('DD.MM.YYYY'),
  },
];

export const bidCardKeys: Card[] = [
  {
    label: 'Менеджер трейдинга',
    key: 'manager',
  },
  {
    label: 'Поставщик',
    key: 'seller',
  },
  {
    label: 'Номенклатуры',
    key: 'shipments',
  },
  {
    label: 'Дата забора',
    key: 'pickup_start_date',
  },
];

export const getBidsChipsLabelNames = (values) => ({
  created__gte: <ChipLabel
    label="Дата создания (от): "
    data={moment(values?.created__gte).format('DD.MM.YYYY')}
  />,
  created__lte: <ChipLabel
    label="Дата создания (до): "
    data={moment(values?.created__lte).format('DD.MM.YYYY')}
  />,
  pickup_start_date__gte: <ChipLabel
    label="Дата забора (от): "
    data={moment(values?.pickup_start_date__gte).format('DD.MM.YYYY')}
  />,
  pickup_start_date__lte: <ChipLabel
    label="Дата забора (до): "
    data={moment(values?.pickup_start_date__lte).format('DD.MM.YYYY')}
  />,
  seller_id__in: <ChipLabel
    label="Поставщик: "
    data={values?.seller_id__in?.map((item) => item.name).join(', ')}
  />,
  manager_id__in: <ChipLabel
    label="Менеджер: "
    data={values?.manager_id__in?.map((item) => item.name).join(', ')}
  />,
  shipments__nomenclature_id__in: <ChipLabel
    label="Номенклатура: "
    data={values?.shipments__nomenclature_id__in?.map((item) => item.name).join(', ')}
  />,
  status__in: <ChipLabel
    label="Статус: "
    data={values?.status__in?.map((item) => item.name).join(', ')}
  />,
});
