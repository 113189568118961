import { Grid } from '@mui/material';
import CustomToolTip from 'src/components/CustomToolTip';

const ToolTipAutocomplete = ({
  nonSelectiveHint,
  messageTooltip,
  iconTooltip,
  havePlatform,
  haveEntity,
  onAutocompleteMouseEnter,
  option,
}) => (
  <>
    {!nonSelectiveHint && messageTooltip && iconTooltip && (
    <Grid
      onMouseEnter={(e) => onAutocompleteMouseEnter(e, option)}
    >
      <CustomToolTip message={messageTooltip} icon={iconTooltip} />
    </Grid>
    )}
    {(nonSelectiveHint && (!havePlatform || !haveEntity)) && (
    <Grid
      onMouseEnter={(e) => onAutocompleteMouseEnter(e, option)}
    >
      <CustomToolTip message={messageTooltip} icon={iconTooltip} />
    </Grid>
    )}
  </>
);

export default ToolTipAutocomplete;
